import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allPosts: {},
    postLoading: false,
    post: {},

    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_POSTS:
            state = {
                ...state,
                loading: false,
                allPosts: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_POSTS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_POSTS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loading: false,
                allPosts: {},
            }
            return state;


        case actions.GET_POST:
            state = {
                ...state,
                postLoading: false,
                post: { ...action.payload.post }
            }
            return state;


        case actions.GET_POST_START:
            state = {
                ...state,
                postLoading: true,
            }
            return state;


        case actions.GET_POST_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                postLoading: false,
                post: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false,
            }
            return state;


        default:
            return state;
    }
}