import React, {Suspense, useEffect, useRef, lazy} from "react";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const GeneralLayout = lazy(() => import("./components/layouts/generalLayout"));
const BlankLayout = lazy(() => import("./components/layouts/blankLayout"));
const NullLayout = lazy(() => import("./components/layouts/nullLayout"));
const Error404 = lazy(() => import("./components/misc/error404"));
const XFrameOptions = lazy(() => import("./components/misc/x-frame-options"));

// const Home = lazy(() => import("./pages/home/home"));
const HomeV1 = lazy(() => import("./pages/home/oldVersions/home-v1"));
const HomeV2 = lazy(() => import("./pages/home/oldVersions/home-v2"));
const HomeV3 = lazy(() => import("./pages/home/oldVersions/home-v3"));
const bookDemo = lazy(() => import("./pages/book-demo"));
const bookDemoV2 = lazy(() => import("./pages/book-demo-v2"));
const productsInvoice = lazy(() => import("./pages/products/invoice"));
const productsOnDemandPay = lazy(() => import("./pages/products/on-demand-pay"));
const productsBulkPayment = lazy(() => import("./pages/products/bulk-payment"));
const productsPayroll = lazy(() => import("./pages/products/payroll"));
const ProductsSavings = lazy(() => import("pages/products/Savings"));
const ProductsRewards = lazy(() => import("pages/products/Rewards"));
const BusinessPayroll = lazy(() => import("pages/business/payroll"));
const Company = lazy(() => import("pages/company"));
// const Employers = lazy(() => import("./pages/Employers"));
// const Employees = lazy(() => import("./pages/Employees"));
const AppointmentSuccess = lazy(() => import("pages/marketingPage/AppointmentSuccess"));
const Terms = lazy(() => import("./pages/others/terms"));
const PrivacyPolicy = lazy(() => import("./pages/others/privacyPolicy"));
const MarketingForm = lazy(() => import("./pages/marketingPage/marketingForm"));
const MarketingRedirectPage = lazy(() => import("./pages/marketingPage/MarketingRedirectPage"));
const WebinarRedirectPage = lazy(() => import("pages/marketingPage/WebinarRedirectPage"));
const WebinarOne = lazy(() => import("./pages/marketingPage/WebinarOne"));
const WebinarTwo = lazy(() => import("pages/marketingPage/WebinarTwo"));
const WebinarThree = lazy(() => import("pages/marketingPage/WebinarThree"));
const WebinarFour = lazy(() => import("pages/marketingPage/WebinarFour"));
const GuideTwo = lazy(() => import("pages/marketingPage/GuideTwo"));
const GuideThree = lazy(() => import("pages/marketingPage/GuideThree"));
const GuideFinHealth = lazy(() => import("pages/marketingPage/GuideFinancialHealth"));
const GuideUltimateJobOfferChecklist = lazy(() => import("pages/marketingPage/GuideUltimateJobOfferChecklist"));
const GuideDaringCeoSeries = lazy(() => import("pages/marketingPage/GuideDaringCeoSeries"));
// const InstantTransfers = lazy(() =>
// 	import('./pages/payments').then((module) => ({
// 	  default: module.InstantTransfers,
// 	}))
//   );

const WebinarFive = lazy(() => import("pages/marketingPage/WebinarFive"));
const WebinarSix = lazy(() => import("pages/marketingPage/WebinarSix"));
const WebinarSeven = lazy(() => import("pages/marketingPage/WebinarSeven"));
const CareersPage = lazy(() => import("pages/careers/CareersPage"));
const CookiePolicy = lazy(() => import("pages/others/CookiePolicy"));
// const CookieConsentModal = lazy(() => import("utils/CookieConsentModal"));
const BusinessPayment = lazy(() => import("pages/business/payment"));
const BusinessODP = lazy(() => import("pages/business/odp"));
const BusinessCredit = lazy(() => import("pages/business/credit"));
const BusinessAccount = lazy(() => import("pages/business/account"));
const BusinessPage = lazy(() => import("pages/business").then((module) => ({
  default: module.BusinessPage,
})))
const PersonalPage = lazy(() => import("pages/products/index").then((module) => ({
  default: module.PersonalPage,
})))

library.add(fas);

const ScrollToTopComponent = () => {
	const mounted = useRef(false);
	useEffect(() => {
		if (!mounted.current) {
			//componentDidMount
			mounted.current = true;
		} else {
			//componentDidUpdate
			window.scrollTo(0, 0);
		}
	});
	return null;
};
export const ScrollToTop = withRouter(ScrollToTopComponent);

const RouteLayout = ({
	component: Component,
	layout: Layout,
	authUser,
	authRole,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => {
			const user = "";
			const user_role = "";

			if (authUser) {
				if (authUser === "loggedIn") {
					if (user.loggedIn === true) {
						if (authRole) {
							if (authRole.includes(user_role)) {
								return (
									<Layout>
										<Component {...props} />
									</Layout>
								);
							} else {
								// alert("Access denied due to user role");
								return <Redirect to="/401" />;
							}
						} else {
							// no roles required, you only need to be logged in
							return (
								<Layout>
									<Component {...props} />
								</Layout>
							);
						}
					} else {
						// alert("need to be logged in);
						return <Redirect to="/login" />;
					}
				} else if (authUser === "loggedOut") {
					if (user.loggedIn === false) {
						return (
							<Layout>
								<Component {...props} />
							</Layout>
						);
					} else {
						// alert("already logged in; need to be logged out to access);
						return <Redirect to="/dashboard" />;
					}
				}
			} else {
				// public page, no auth-yes or auth-no required
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

function App() {
	return (
		<React.Fragment>
			<BrowserRouter>
				<ScrollToTop />
				{/* <CookieConsentModal /> */}
				<Suspense fallback={<></>}>
					<Switch>
						<RouteLayout exact path="/book-demo" layout={BlankLayout} component={bookDemo} />
						<RouteLayout exact path="/book-demo/v2" layout={BlankLayout} component={bookDemoV2} />

						{/* <RouteLayout exact path="/products/invoice" layout={GeneralLayout} component={productsInvoice} /> */}
						{/* <RouteLayout exact path="/personal/on-demand-pay" layout={GeneralLayout} component={productsOnDemandPay} /> */}
						{/* <RouteLayout exact path="/personal/credit" layout={GeneralLayout} component={productsBulkPayment} /> */}
						{/* <RouteLayout exact path="/products/payroll" layout={GeneralLayout} component={productsPayroll} /> */}
						{/* <RouteLayout exact path="/products/payroll" layout={GeneralLayout} component={productsPayroll} /> */}
						{/* <RouteLayout exact path="/careers" layout={GeneralLayout} component={CareersPage} /> */}
						{/* <RouteLayout exact path="/personal/savings" layout={GeneralLayout} component={ProductsSavings} /> */}
						{/* <RouteLayout exact path="/personal/rewards" layout={GeneralLayout} component={ProductsRewards} /> */}


						<RouteLayout exact path="/business" layout={GeneralLayout} component={BusinessPage} />
						<RouteLayout exact path="/company" layout={GeneralLayout} component={Company} />
						<RouteLayout exact path="/business/account" layout={GeneralLayout} component={BusinessAccount} />
						<RouteLayout exact path="/business/credit" layout={GeneralLayout} component={BusinessCredit} />
						<RouteLayout exact path="/business/payment" layout={GeneralLayout} component={BusinessPayment} />
						<RouteLayout exact path="/business/payroll" layout={GeneralLayout} component={BusinessPayroll} />
						<RouteLayout exact path="/business/odp" layout={GeneralLayout} component={BusinessODP} />
						<RouteLayout exact path="/personal" layout={GeneralLayout} component={PersonalPage} />
						
						<RouteLayout exact path="/terms" layout={GeneralLayout} component={Terms} />
						<RouteLayout exact path="/privacy-policy" layout={GeneralLayout} component={PrivacyPolicy} />
						<RouteLayout exact path="/cookie-policy" layout={GeneralLayout} component={CookiePolicy} />

						<RouteLayout exact path="/campaign/guide/5-ways-employee-financial-stress-is-costing-your-business" layout={GeneralLayout} component={MarketingForm} />
						<RouteLayout exact path="/campaign/guide/how-to-stop-living-from-payday-to-payday" layout={GeneralLayout} component={GuideTwo} />
						<RouteLayout exact path="/campaign/guide/the-future-of-employee-benefits" layout={GeneralLayout} component={GuideThree} />
						<RouteLayout exact path="/campaign/guide/improving-the-financial-health-of-your-employees" layout={GeneralLayout} component={GuideFinHealth} />
						<RouteLayout exact path="/campaign/guide/the-ultimate-job-offer-checklist" layout={GeneralLayout} component={GuideUltimateJobOfferChecklist} />
						<RouteLayout exact path="/campaign/guide/the-daring-ceo-series" layout={GeneralLayout} component={GuideDaringCeoSeries} />
						<RouteLayout exact path="/campaign/guide/thank-you" layout={GeneralLayout} component={MarketingRedirectPage} />
						<RouteLayout exact path="/campaign/webinar/survival-skills-for-women" layout={GeneralLayout} component={WebinarOne} />
						<RouteLayout exact path="/campaign/webinar/navigating-through-a-toxic-workplace-culture" layout={GeneralLayout} component={WebinarTwo} />
						<RouteLayout exact path="/campaign/webinar/how-to-keep-your-employees-motivated-even-in-tough-times" layout={GeneralLayout} component={WebinarThree} />
						<RouteLayout exact path="/campaign/webinar/how-to-build-a-compelling-talent-attraction-strategy" layout={GeneralLayout} component={WebinarFour} />
						<RouteLayout exact path="/campaign/webinar/how-do-i-keep-my-best-talent" layout={GeneralLayout} component={WebinarFive} />
						<RouteLayout exact path="/campaign/webinar/helping-employees-thrive-through-disruptions" layout={GeneralLayout} component={WebinarSix} />
						<RouteLayout exact path="/campaign/webinar/improving-employee-experience-through-financial-wellness" layout={GeneralLayout} component={WebinarSeven} />
						<RouteLayout exact path="/campaign/webinar/thank-you" layout={GeneralLayout} component={WebinarRedirectPage} />
						<RouteLayout exact path="/appointment/success" layout={GeneralLayout} component={AppointmentSuccess} />

						<RouteLayout exact path="/404" layout={NullLayout} component={Error404} />
						<RouteLayout exact path="/test/clickjacking" layout={NullLayout} component={XFrameOptions} />

						{/* get v0 from gitlab */}
						<RouteLayout exact path="/versions/v1" layout={GeneralLayout} component={HomeV1} />
						<RouteLayout exact path="/versions/v2" layout={GeneralLayout} component={HomeV2} />
						<RouteLayout exact path="/versions/v3" layout={GeneralLayout} component={HomeV3} />
						{/* <RouteLayout exact path="/" layout={GeneralLayout} component={Home} /> */}
						<Route exact path="/">
							<Redirect to="/business" />
						</Route>

						<Redirect from="/index.html" to="/" />
						<Redirect from="/sitemap.xml" to="/sitemap.xml" />
						{/* <Redirect from="/faq" to="/faqs" /> */}

						<Redirect from="/home" to="/" />
						<Redirect to="/404" />
					</Switch>
				</Suspense>
			</BrowserRouter>
		</React.Fragment>
	);
}

export default App;
