export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
