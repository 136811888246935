// import http, {useLoginJwt, dontUseLoginJwt} from "services/httpService"
import axios from "axios";
import * as config from "config";
import * as userActions from "store/auth/user/actionTypes";

const api = store => next => async action => {

    if (action.type !== config.apiRequestStart) {
        next(action);
    }
    else {

        const { url, method, data, onStart, onSuccess, onError, useLoginJwt } = action.payload;
        if (onStart) {
            store.dispatch({ type: onStart });
        }

        let http;
        if ((typeof useLoginJwt !== "undefined") && (useLoginJwt === false)) {
            http = axios.create();
        }
        else {
            const loginJwt = store.getState().auth.userPersist.data.token;
            http = axios.create({
                headers: {
                    Authorization: loginJwt,
                }
            });
        }

        next(action);                                                                           // for debugger logging purpose (in chrome Redux dev tools)

        await http.request({
            baseURL: config.apiBaseUrl,
            url,
            method,
            data
        }).then((response) => {
            store.dispatch({ type: config.apiRequestSuccess, payload: response });                // for debugger logging purpose
            if (onSuccess) {
                store.dispatch({ type: onSuccess, payload: response.data });
            }
        }).catch((error) => {
            // console.log(error); //check with dummy base64 endpoint online
            store.dispatch({ type: config.apiRequestFailed, payload: error });                    // for debugger logging purpose
            if (onError) {
                if (error.response) {
                    store.dispatch({ type: onError, payload: error.response.data });
                    if (error.response.data.message === "Expired Session, Please Login Again") {
                        // window.setTimeout(function () {
                            store.dispatch({ type: userActions.LOGOUT_USER });
                        // }, 1000);
                    }
                }
                else if (error.request) {
                    if (navigator.onLine) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Error: Invalid request. Please try again later."
                            }
                        });
                    }
                    else {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "No Internet Connection"
                            }
                        });
                    }
                }
                else {
                    store.dispatch({
                        type: onError,
                        payload: {
                            message: "An error occurred. Please try again later."
                        }
                    });
                }
            }
        });

    }

}

export default api;