import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allCategories: {},
    getCategoryLoading: false,
    getCategory: {},

    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_CATEGORIES:
            state = {
                ...state,
                loading: false,
                allCategories: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_CATEGORIES_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_CATEGORIES_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loading: false,
                allCategories: {},
            }
            return state;


        case actions.GET_CATEGORY:
            state = {
                ...state,
                getCategoryLoading: false,
                getCategory: { ...action.payload.category }
            }
            return state;


        case actions.GET_CATEGORY_START:
            state = {
                ...state,
                getCategoryLoading: true,
            }
            return state;


        case actions.GET_CATEGORY_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                getCategoryLoading: false,
                getCategory: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false,
            }
            return state;


        default:
            return state;
    }
}