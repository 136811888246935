
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_START = "GET_ALL_CATEGORIES_START";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_START = "GET_CATEGORY_START";
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";
