
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_ALL_POSTS_START = "GET_ALL_POSTS_START";
export const GET_ALL_POSTS_FAILED = "GET_ALL_POSTS_FAILED";

export const GET_POST = "GET_POST";
export const GET_POST_START = "GET_POST_START";
export const GET_POST_FAILED = "GET_POST_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";
