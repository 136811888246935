import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    createReferralLoading: false,
    createReferralSuccess: false,
    referralForm: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.CREATE_REFERRAL:
            state = {
                ...state,
                createReferralLoading: false,
                createReferralSuccess: true,
                referralForm: { ...action.payload }
            }
            return state;


        case actions.CREATE_REFERRAL_START:
            state = {
                ...state,
                createReferralLoading: true,
                createReferralSuccess: false,
            }
            return state;


        case actions.CREATE_REFERRAL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                createReferralLoading: false,
                createReferralSuccess: false,
                referralForm: {},
            }
            return state;


        default:
            return state;
    }
}