import React from 'react';
import ReactDOM from 'react-dom';
import store, { persistor } from "store/store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App';
import UnsecurePageAlert from './components/misc/UnsecurePageAlert';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import 'assets/css/index.css';
import 'assets/css/earnipay.css';


Sentry.init({
  dsn: "https://92a90cc53bcd48d696cfae0192d92478@o1085459.ingest.sentry.io/6096200",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <ToastContainer autoClose={5000} />
    
    {(window.self === window.top) &&
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
    }

    {(window.self !== window.top) && <UnsecurePageAlert /> }

  </React.StrictMode>,
  document.getElementById('root')
);