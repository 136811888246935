// Main
export const projectName = 'Earnipay';
export const repoName = 'earnipay-website';

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS";     // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED";       // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// API - user auth
export const apiCreateReferral = "/miscellaneous/referral/invite/send";

// API - financial education
export const apiCategories = "/financial-education/categories";
export const apiPosts = "/financial-education/posts";

// API - banks
export const apiGetBanks = "/miscellaneous/get-bank-list";
