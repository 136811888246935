import { combineReducers } from "redux";
import referralReducer from "./referral/reducer";
import categoriesReducer from "./categories/reducer";
import postsReducer from "./posts/reducer";

export default combineReducers({
    referral: referralReducer,
    categories: categoriesReducer,
    posts: postsReducer,
});
